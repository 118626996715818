import { round } from "lodash";
import Dinero from "dinero.js";

export const formatDateWithTimezone = (date) => {
  const formater = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
    timeZoneName: "short",
  });
  try {
    return formater.format(new Date(date));
  } catch (err) {
    return "";
  }
};

export const formatCurrency = ({ amount, currency }) => {
  if (!amount && amount !== 0) {
    return "";
  }

  try {
    return Dinero({ amount: Math.round(amount * 100), currency })
      .setLocale("fr")
      .toFormat()
      .toUpperCase();
  } catch (err) {
    return amount;
  }
};

export const accordeonDetailsPolicies = (translationData, policies) => {
  const policiesFormatted = [
    {
      description: { displayName: translationData.displayName },
      content: `${policies?.cancelConditions
        .map((cc) => {
          return `${translationData.from}
          "${formatDateWithTimezone(cc.startAt)}" ${translationData.to}${" "}
          "${formatDateWithTimezone(cc.endAt)}"${", "}
          ${translationData.cancellationFees} ${"= "} ${formatCurrency({
            amount: round(cc.deduction, 2),
            currency: cc.currency ?? translationData.currency,
          })}<br><br>`;
        })
        .join("")}${
        !!policies?.contractingConditions ? policies.contractingConditions : ""
      }`,
    },
  ];
  return policiesFormatted ?? "";
};
