import React from 'react';
import styled, { css } from 'styled-components';
import { useField } from 'formik';
import { FormErrorMessage } from 'ui/forms';

const CheckboxText = styled.label`
  color: ${({ theme }) => theme.textLighter};
  text-align: left;
  font-size: 18px;

  ${props =>
    props.isLink &&
    css`
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
    `}
    ${props =>
      props.inCardPay &&
      css`
        font-size: 16px;
        color: #000;
        display: inline-block;
        float: left;
        padding-right: 4px;
    `}
`;



const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: ${({ theme }) => theme.spacing()};
    
  }

  ${props =>
    props.inCardPay &&
    css`
      float:left;
    `}
`;

const Checkbox = styled.input``;

const CheckboxField = ({ label, isLink,inCardPay, disabled,  ...props }) => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;

  return (
    <>
      <CheckboxWrapper inCardPay={inCardPay}>
        <Checkbox
          type="checkbox"
          checked={field.value}
          {...field}
          disabled={disabled}
          data-cy={`checkbox-${props.name}`}
        />
        <div>
          <CheckboxText className={props.nowrapLabel ? 'whitespace-nowrap': ''} isLink={isLink} inCardPay={inCardPay} htmlFor={`checkbox-${props.name}`}>
            {label}
          </CheckboxText>
        </div>
      </CheckboxWrapper>
      {touched && error && <FormErrorMessage error>{error}</FormErrorMessage>}
    </>
  );
};

export default CheckboxField;
