import styled, { css, useTheme } from 'styled-components';
import { round, times } from 'lodash';
import {
  MdCalendarToday,
  MdChildCare,
  MdStar,
  MdTrendingFlat,
} from 'react-icons/md';
import placeholderHotel from '../../../images/placeholderHotel.png';
import {
  Cell,
  Grid,
  SectionCard,
  Text,
} from 'components/Hotel/Booking/components';
import { useTranslation } from 'react-i18next';
import { Spinner, Stack } from '@koob/margaret';
import {
  IoMoonOutline,
  IoPeopleOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import { IcOrganization } from 'components/icons';
import { formatCurrency, formatDate } from 'utils';
import { differenceInDays, differenceInYears } from 'date-fns';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { getBooking } from 'api/bookings';
import { getHotel } from 'api/hotels';
import { SpinnerContainer } from 'containers/Hotels/HotelsDetail';
import KoobCard from '../../Koob/KoobCard';
import KoobContainer from '../../Koob/KoobContainer';
import KoobTitle from '../../Koob/KoobTitle';
import KoobParagraph from '../../Koob/KoobParagraph';
import KoobButton from '../../Koob/KoobButton';
import ExternalBookingReference from '../../Booking/ExternalBookingReference';
import React from 'react';
import { useBooking } from 'hooks';
import Tooltip from 'components/Tooltip';
import KoobLayout from '../../Koob/KoobLayout';
import IcLightning from 'components/icons/IcLightning';
import { KOEDIA } from '../../../constants';
import { HotelBookingPolicies } from '@koob/booking';

const RecapTitle = styled.div`
  margin-bottom: 1rem !important;
  font-size: 18px;
  font-weight: 600;
  color: gray;
`;

const HotelPic = styled.div`
  background-image: url('${props => props.image}');
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 145px;
`;

const HotelName = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing()};
`;
const HotelAdress = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;
const Rate = styled(Stack)`
  color: ${({ theme }) => theme.yellow};
  font-size: 24px;
`;

const NormalContent = styled.div`
  margin-top: 0 !important;
  display: flex;

  align-items: center;
`;

const ItemCard = styled(Stack).attrs({
  padding: 0.5,
  gutterSize: 0.5,
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const CardTitle = styled(Text).attrs({ type: 'bodyLarge' })`
  font-weight: bold;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const UI = {
  Wrapper: styled(Stack).attrs({ direction: 'row', gutterSize: 2 })``,
  CardTitle: styled(CardTitle)`
    margin-block: 0;
    display: flex;
    width: 100%;
    font-size: 1.2rem;
  `,
  UserIcon: ({ kind = 'adult', color = 'inherit' }) => {
    return (
      <Stack>
        {kind === 'child' && (
          <MdChildCare style={{ color }} aria-hidden="true" size={24} />
        )}
        {kind === 'adult' && (
          <HiOutlineUserCircle style={{ color }} aria-hidden="true" size={24} />
        )}
      </Stack>
    );
  },
  DetailsSectionCard: styled(SectionCard)`
    display: grid;
    grid-auto-flow: row;
    border: 0;
    gap: ${({ theme }) => theme.spacing(0.5)};

    ul {
      list-style: none;
      padding: unset;
      margin: 0;
    }
  `,
  DetailsSectionItemInner: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    column-gap: 10px;

    > * {
      margin: 0;
    }

    time {
      grid-column: 2;
    }

    .full {
      grid-column: 1 / -1;
    }
  `,
  DetailSectionItem: styled.li`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(0.75)};
    padding-block: ${({ theme }) => theme.spacing(0.5)};
    padding-inline: 0;

    &:not(:last-of-type) {
      border-block-end: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  `,
  Text: styled.p`
    margin-top: 0;
    padding: 0;
    color: ${({ theme }) => theme.textLight};
    ${({ isName }) =>
      isName
        ? css`
            font-weight: bold;
            font-size: 1rem;
            color: ${({ theme }) => theme.text};
          `
        : undefined};
  `,
};

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.separator};
`;

const OptionWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.5) !important;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(0.295)}
    ${({ theme }) => theme.spacing(0.6)};
  text-align: center;

  ${props =>
    props.greenOption &&
    css`
      background-color: rgba(115, 201, 146, 0.12);
      color: ${({ theme }) => theme.primary} !important;
    `}

  ${props =>
    props.orangeOption &&
    css`
      background-color: #47b7dc;
      color: #ea5d5d !important;
    `}

    ${props =>
    props.blueOption &&
    css`
      background-color: rgba(0, 0, 255, 0.12);
      color: #2e1065;
    `}
`;

const NoteBloc = styled.div`
  max-height: 500px;
  overflow: auto;
`;

export const BookingState = ({state, type}) => {
  const {t} = useTranslation('booking');
  return (
    <>
      {state === 'sent' && (
        <div className="py-8 mb-5 flex justify-center items-center gap-5">
          <svg className="h-24 w-24 text-blue-500" viewBox="0 0 512 512">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM385 215c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V392c0 13.3-10.7 24-24 24s-24-10.7-24-24V177.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 103c9.4-9.4 24.6-9.4 33.9 0L385 215z" />
          </svg>

          <div className="max-w-sm">
            <KoobTitle>{t('state.sent.title')}</KoobTitle>
            <KoobParagraph>{t('state.sent.description')}</KoobParagraph>
          </div>
        </div>
      )}
      {(state === 'pending' || state === 'pending_source') && (
        <div className="py-8 mb-5 flex justify-center items-center gap-5">
          <svg className="h-24 w-24 text-orange-600" viewBox="0 0 512 512">
            <path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
          </svg>

          <div className="max-w-sm">
            <KoobTitle>{t('state.pending.title')}</KoobTitle>
            <KoobParagraph>{t('state.pending.description')}</KoobParagraph>
          </div>
        </div>
      )}
      {state === 'confirmed' && (
        <div className="py-8 mb-5 flex justify-center items-center gap-5">
          <svg className="h-24 w-24 text-green-500" viewBox="0 0 512 512">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
          </svg>

          <div className="max-w-sm">
            <KoobTitle>{t('state.confirmed.title')}</KoobTitle>
            <KoobParagraph>
              {t('state.confirmed.description', {
                type: type,
              })}
            </KoobParagraph>
          </div>
        </div>
      )}
      {state === 'refused' && (
        <div className="py-8 mb-5 flex justify-center items-center gap-5">
          <svg className="h-24 w-24 text-red-500" viewBox="0 0 512 512">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>

          <div className="max-w-sm">
            <KoobTitle>{t('state.refused.title')}</KoobTitle>
            <KoobParagraph>{t('state.refused.description')}</KoobParagraph>
          </div>
        </div>
      )}
      {state === 'failure' && (
        <div className="py-8 mb-5 flex justify-center items-center gap-5">
          <svg className="h-24 w-24 text-red-500" viewBox="0 0 512 512">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>

          <div className="max-w-sm">
            <KoobTitle>{t('state.failure.title')}</KoobTitle>
            <KoobParagraph>{t('state.failure.description')}</KoobParagraph>
          </div>
        </div>
      )}
    </>
  );
};

const BookingRecap = () => {
  const { bookingId } = useParams();
  const { filters, costumerFile } = useBooking();
  const { data: bookingData, loading: loadingBooking } = useAsync({
    promiseFn: getBooking,
    bookingId,
  });
  const booking = bookingData?.data;
  const { data: hotelData, loading: loadingHotel } = useAsync({
    promiseFn: getHotel,
    hotelId: booking?.hotelId,
    watch: booking?.hotelId,
  });
  const hotel = hotelData?.data;
  const { t } = useTranslation('booking');
  const theme = useTheme();
  const nbNights = differenceInDays(
    new Date(booking?.endAt),
    new Date(booking?.startAt),
  );
  const img = hotel?.primaryAttachment?.imageUrl;
  let originalTotalPriceWithPromo = 0;
  booking?.roomsBooked.forEach(({ room }) => {
    originalTotalPriceWithPromo += parseFloat(
      room['bedChoice']['totalPriceWithPromo'],
    );
  });
  const hasNewCostumerFile = filters?.experienceId && !costumerFile;
  const programCostumerFile = {
    experienceId: filters?.experienceId,
    bookedHotel: booking?.hotelId,
    programDay: filters.programDay,
    costumerFileId: hasNewCostumerFile ? booking?.customerFileId : undefined,
  };
  localStorage.setItem(
    'programCostumerFile',
    JSON.stringify(programCostumerFile),
  );

  const totalTravelers = Math.max(
    booking?.roomsBooked?.reduce((acc, curr) => {
      acc += curr.travelers.length;
      return acc;
    }, 0),
    1,
  );
  const policiesData = {
    displayName: t('contractingConditions'),
    from: t('room.from'),
    to: t('room.to'),
    cancellationFees: t('room.cancellationFees'),
    currency: booking?.currency,
  };

  return (
    <KoobLayout>
      <KoobContainer>
        {(loadingHotel || loadingBooking) && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        {!loadingHotel && !loadingBooking && (!hotel || !booking) && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}

        {booking && hotel && (
          <div>
            <BookingState state={booking.state} type={t('hotel')} />

            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <KoobCard className="mb-5">
                  <RecapTitle>{t('bookingDetails')}</RecapTitle>

                  <Grid>
                    <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
                      {Boolean(img) ? (
                        <HotelPic image={img} alt="" />
                      ) : (
                        <HotelPic image={placeholderHotel} alt="" />
                      )}
                    </Cell>

                    <Cell sizes={{ default: 1, tabletprod: 1 / 2 }}>
                      <HotelName>{hotel?.displayName}</HotelName>

                      <HotelAdress>{hotel?.address}</HotelAdress>

                      <Stack style={{ position: 'relative' }}>
                        <Rate>
                          {times(hotel?.stars ?? 0, index => (
                            <MdStar key={index} />
                          ))}
                        </Rate>
                      </Stack>

                      <ExternalBookingReference booking={booking} />

                      <Stack />
                    </Cell>

                    <Cell />
                  </Grid>

                  <Stack size="full" alignY="center" gutterSize={1.25}>
                    <ItemCard alignY="center">
                      {booking?.startAt && booking?.endAt && (
                        <NormalContent direction="row">
                          {formatDate(booking?.startAt, 'dd/MM/yyyy')}{' '}
                          <MdTrendingFlat size={18} color={'#D6D6D6'} />{' '}
                          {formatDate(booking?.endAt, 'dd/MM/yyyy')}
                        </NormalContent>
                      )}
                    </ItemCard>

                    {booking?.startAt && booking?.endAt && (
                      <Stack
                        alignY="center"
                        gutterSize={0.5}
                        style={{ marginTop: '0px' }}
                      >
                        <IoMoonOutline size={18} />

                        <span>
                          {t('summary.nNightsStay', {
                            count: nbNights,
                            days: nbNights + 1,
                          })}
                        </span>
                      </Stack>
                    )}

                    <ItemCard alignY="center">
                      {booking?.roomsBooked && (
                        <Stack
                          gutterSize={0.25}
                          alignY="center"
                          style={{ marginTop: '0px' }}
                        >
                          <IoPeopleOutline size={18} />
                          <span>
                            {t('summary.nGuests', {
                              count: totalTravelers,
                            })}
                          </span>
                        </Stack>
                      )}

                      {booking?.roomsBooked && (
                        <Stack gutterSize={0.25} alignY="center">
                          <IcOrganization size={18} />
                          <span>
                            {t('summary.nRooms', {
                              count: booking?.roomsBooked.length,
                            })}
                          </span>
                        </Stack>
                      )}
                    </ItemCard>
                  </Stack>

                  <div className="mt-3 flex justify-between">
                    <div className="font-semibold text-right">
                      <div>
                        {booking?.allotmentAvailable && (
                          <Tooltip
                            tip={t('allotments:tooltip')}
                            hasArrow={false}
                            color="purple"
                          >
                            <IcLightning size={24} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div className="font-semibold text-right">
                      <div>
                        <div className="text-xl text-orange-600" data-cy="totalPrice">
                          {formatCurrency({
                            amount: originalTotalPriceWithPromo,
                            currency: booking?.currency,
                          })}
                        </div>

                        <div className="text-gray-600">
                          {formatCurrency({
                            amount:
                              originalTotalPriceWithPromo / totalTravelers,
                            currency: booking?.currency,
                          })}{' '}
                          / PAX
                        </div>
                      </div>
                    </div>
                  </div>
                </KoobCard>

                {booking?.roomsBooked.map(({ room, travelers }, index) => (
                  <KoobCard
                    className="mb-5"
                    key={index}
                    style={{ paddingInline: '0px' }}
                  >
                    <UI.DetailsSectionCard>
                      <Stack
                        direction="row"
                        gutterSize={1}
                        alignY="start"
                        style={{
                          marginBottom: theme.spacing(0.5),
                          justifyContent: 'space-between',
                        }}
                      >
                        <UI.CardTitle
                          as="h2"
                          style={{ width: 'fit-content', maxWidth: '60%' }}
                        >
                          {room?.name ?? 'N/A'}{' '}
                          {room?.bedChoice?.name
                            ? `(${room?.bedChoice?.name})`
                            : ''}
                        </UI.CardTitle>

                        <Stack direction="column" alignX="end">
                          <Stack direction="row" gutterSize={1}>
                            <div className="text-orange-600 font-medium">
                              {formatCurrency({
                                amount: room.bedChoice.totalPriceWithPromo,
                                currency: booking?.currency,
                              })}
                            </div>
                          </Stack>

                          <Stack direction="row" gutterSize={1}>
                            <div className="text-sm text-gray-700">
                              {formatCurrency({
                                amount:
                                  room.bedChoice.totalPriceWithPromo /
                                  Math.max(travelers?.length, 1),
                                currency: booking?.currency,
                              })}{' '}
                              /PAX
                            </div>
                          </Stack>

                          <Stack direction="row">
                            <div className="mt-3 font-medium text-gray-800">
                              {t('summary.pricePerNight', {
                                price: formatCurrency({
                                  amount: round(
                                    room.bedChoice.totalPriceWithPromo /
                                      nbNights,
                                    2,
                                  ),
                                  currency: booking?.currency,
                                }),
                              })}
                            </div>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack style={{ flexWrap: 'wrap' }}>
                        {room.bedChoice.promotions &&
                          room.bedChoice.promotions.map((promo, index) => (
                            <OptionWrapper
                              key={'promo_' + index}
                              greenOption
                              style={{ marginRight: '5px' }}
                            >
                              {promo?.split(/[+-]/)[0].trim()}
                            </OptionWrapper>
                          ))}

                        {room.bedChoice.mandatorySupplements &&
                          room.bedChoice.mandatorySupplements.map(
                            (supplement, index) => (
                              <Tooltip
                                key={'supplement_' + index}
                                tip={t('summary.requiredSupplement')}
                                position="top"
                                hasArrow={false}
                                color="purple"
                              >
                                <OptionWrapper
                                  blueOption
                                  style={{ marginRight: '5px' }}
                                >
                                  {supplement}
                                </OptionWrapper>
                              </Tooltip>
                            ),
                          )}
                      </Stack>

                      <Separator
                        style={{
                          width: `calc(100% + ${theme.spacing(2)})`,
                          marginLeft: theme.spacing(-1),
                          marginTop: theme.spacing(0.75),
                          marginBottom: theme.spacing(0.25),
                        }}
                      />

                      <div>
                        {travelers?.map((traveler, index) => (
                          <div
                            key={index}
                            style={{ display: 'flex', gap: '1rem' }}
                          >
                            <UI.UserIcon
                              kind={traveler?.type}
                              color={theme.textLighter}
                            />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <UI.Text isName>
                                {traveler?.firstName} {traveler?.lastName}
                              </UI.Text>

                              {traveler?.birthdate && (
                                <Stack direction="row" alignY="center">
                                  <MdCalendarToday
                                    style={{ color: theme.textLighter }}
                                    size={18}
                                    aria-hidden="true"
                                  />

                                  <div
                                    dateTime={traveler?.birthdate}
                                    style={{ marginLeft: theme.spacing(0.5) }}
                                  >
                                    {formatDate(
                                      traveler?.birthdate,
                                      'dd/MM/yyyy',
                                    )}
                                  </div>

                                  {differenceInYears(
                                    new Date(),
                                    new Date(traveler.birthdate),
                                  ) < 18 && (
                                    <div className="flex items-center space-x-1 pl-4">
                                      <input
                                        type="checkbox"
                                        checked={traveler?.ageIsExact}
                                      />

                                      <div className="flex items-center space-x-1">
                                        <label>
                                          {t('form.definitiveDate')}
                                        </label>

                                        {!traveler?.ageIsExact && (
                                          <Tooltip
                                            tip={t('form.birthdateTip')}
                                            color="purple"
                                            hasArrow={false}
                                            position="top"
                                          >
                                            <IoWarningOutline
                                              color="red"
                                              size={20}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Stack>
                              )}

                              {traveler?.nationality && (
                                <Stack
                                  as="dl"
                                  direction="column"
                                  className="full"
                                >
                                  <Stack>
                                    <dt>{t('form.nationality')} :</dt>
                                    <dd>&nbsp;{traveler?.nationality}</dd>
                                  </Stack>
                                </Stack>
                              )}

                              {traveler?.passportNumber && (
                                <Stack
                                  as="dl"
                                  direction="column"
                                  className="full"
                                >
                                  <Stack>
                                    <dt>{t('form.passportNumber')}:</dt>
                                    <dd>&nbsp;{traveler?.passportNumber}</dd>
                                  </Stack>

                                  <Stack>
                                    <dt>
                                      {t('form.expirationDate', {
                                        ns: 'bookingForm',
                                      })}{' '}
                                      :
                                    </dt>
                                    <dd>
                                      &nbsp;
                                      <time dateTime={traveler?.expirationDate}>
                                        {formatDate(
                                          traveler?.expirationDate,
                                          'dd/MM/yyyy',
                                        )}
                                      </time>
                                    </dd>
                                  </Stack>
                                </Stack>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </UI.DetailsSectionCard>
                  </KoobCard>
                ))}

                <HotelBookingPolicies
                  policies={booking?.roomsBooked?.[0]?.room?.bedChoice}
                  translationData={policiesData}
                />
              </div>

              <div>
                <KoobCard className="mb-5">
                  <CardTitle>{t('form.tourOperator')}</CardTitle>

                  <p>{booking?.operatorTourName || booking?.customerCompany}</p>
                </KoobCard>

                <KoobCard className="mb-5">
                  <CardTitle>{t('form.bookingReferee')}</CardTitle>

                  <div>
                    {booking?.customerFirstName} {booking?.customerLastName}
                  </div>
                  <div>{booking?.customerCompany}</div>
                  <div>{booking?.customerEmail}</div>
                  <div>{booking?.customerPhoneNumber}</div>
                </KoobCard>

                <KoobCard>
                  <CardTitle>{t('form.notes')}</CardTitle>
                  <NoteBloc className="h-100 border-gray-300 p-4">
                    <Text type="body" color="black">
                      <div
                        className="ql-editor"
                        dangerouslySetInnerHTML={{
                          __html: booking?.note,
                        }}
                      />
                    </Text>
                  </NoteBloc>
                </KoobCard>
              </div>
            </div>

            <div className="py-8 flex justify-center">
              <Link to="/hotels">
                <KoobButton cypressId="back-to-hotels">{t('backToHotels')}</KoobButton>
              </Link>
            </div>
          </div>
        )}
      </KoobContainer>
    </KoobLayout>
  );
};
export default BookingRecap;
