import { get, post } from './';

export const getHotels = ({ filters, locale, skip = false }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return post(`/api/v1/hotels?locale=${locale ?? 'en'}`, filters);
};

export const getHotel = async ({ hotelId, locale, skip }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return get(`/api/v1/hotels/${hotelId}?locale=${locale ?? 'en'}`);
};

export const getHotelDmcRoom = ({ id, filters }) => {
  return post(`/api/v2/hotels/${id}/roomsAvailable`, filters);
};

export const getRoomsCompositionPolicies = ({ policiesRequest }) =>
  post(`/api/v2/hotels/roomsPolicies`, policiesRequest);

export const submitBook = ({ id, data }) => {
  return post(`/api/v1/hotels/${id}/book`, data);
};
