import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ComposeDayExperience from './ComposeDayExperience';
import ComposeDayHotel from './ComposeDayHotel';
import ComposeDayInsertBetween from './ComposeDayInsertBetween';
import ComposeDayItemSelectorDisabled from './ComposeDayItemSelectorDisabled';
import ExperienceProgramContentHeader from '../../../Experiences/Partials/ExperienceProgramContentHeader';
import { ProgramAccommodationTypeEnum } from '@koob/enums';

export default function ComposeDay({
  dayIndex,
  date,
  addHotel,
  addExperience,
  getHotelDayIndex,
  setOptions,
  currentVersion,
  formatExtras,
  setFormatExtras,
  finalizing,
  setHasCurrencyMismatch
}) {
  const { t } = useTranslation('tripDesigner');
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);
  const [{ value: bookOnMyOwnValue }, , { setValue: setBookOnMyOwn }] = useField(`days[${dayIndex}].hotelBookedOnMyOwn`);
  const [{ value: days }] = useField('days');

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  const accomodationType = program?.accomodationType;
  const accomodationName = program?.accomodationExperience?.name;

  const needsHotel =
    ![
      ProgramAccommodationTypeEnum.NIGHT_TRANSFER,
      ProgramAccommodationTypeEnum.NO_ACCOMMODATION,
      ProgramAccommodationTypeEnum.FREE_ACCOMMODATION
    ].includes(accomodationType)
    || (accomodationType === ProgramAccommodationTypeEnum.FREE_ACCOMMODATION && !bookOnMyOwnValue);

  const hotelSelectorComponent = () => {
    if (bookOnMyOwnValue) {
      return (
        <ComposeDayItemSelectorDisabled>
          {t('compose.hotelWillBeBookedOnMyOwn')}

          <button
            onClick={() => setBookOnMyOwn(undefined)}
            className="mt-1.5 border-none text-orange-500"
          >
            {t('compose.resetChoice')}
          </button>
        </ComposeDayItemSelectorDisabled>
      );
    }

    if (needsHotel) {
      return (
        <ComposeDayHotel
          dayIndex={dayIndex}
          addHotel={addHotel}
          date={date}
          getHotelDayIndex={getHotelDayIndex}
        />
      );
    }

    if (accomodationType === ProgramAccommodationTypeEnum.NIGHT_TRANSFER) {
      return (
        <ComposeDayItemSelectorDisabled>
          {accomodationName}
        </ComposeDayItemSelectorDisabled>
      );
    }

    return (
      <ComposeDayItemSelectorDisabled>
        {t('compose.noAccomodation')}
      </ComposeDayItemSelectorDisabled>
    );
  };

  const previousExperience = days?.[dayIndex - 1]?.experience;
  const nextExperienceId = days?.[dayIndex + 1]?.experience?.id;

  const isDisabled = nextExperienceId !== experience?.id &&
      previousExperience?.incompatibilities?.some(item => item?.id === nextExperienceId);

  return (
    <div>
      <ComposeDayInsertBetween
        dayIndex={dayIndex}
        position="before"
        experience={experience}
        isDisabled={isDisabled}
        translation={t}
      />

      <ExperienceProgramContentHeader
        title={`${t('compose.day')} ${dayIndex+1}`}
        date={date}
        location={
          experience?.inCity
            ? `${experience?.inCity?.title}, ${experience?.inCity?.country?.title}`
            : '--'
        }
      />

      <div className="my-3 grid grid-cols-7 gap-1">
        <div className="col-span-4">
          <ComposeDayExperience
            dayIndex={dayIndex}
            date={date}
            setOptions={setOptions}
            currentVersion={currentVersion}
            formatExtras={formatExtras}
            setFormatExtras={setFormatExtras}
            finalizing={finalizing}
            setHasCurrencyMismatch={setHasCurrencyMismatch}
            addExperience={addExperience}
          />
        </div>

        <div className="col-span-3">
          {hotelSelectorComponent()}
        </div>
      </div>

      <ComposeDayInsertBetween
        dayIndex={dayIndex}
        position="after"
        experience={experience}
        isDisabled={isDisabled}
        translation={t}
      />
    </div>
  )
}
