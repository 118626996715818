import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { getTrip } from '../../../api/trip';
import htmlToPdf, { prepareDocumentForHtmlToPdf } from '@koob/html-to-pdf';
import KoobLayout from '../../../components/Koob/KoobLayout';
import ReceiptLoading from './Partials/ReceiptLoading';
import KoobContainer from '../../../components/Koob/KoobContainer';
import ReceiptWrapper from './Partials/ReceiptWrapper';
import ReceiptHeader from './Partials/ReceiptHeader';
import ReceiptContent from './Partials/ReceiptContent';
import ReceiptCancellationPolicies from './Partials/ReceiptCancellationPolicies';
import moment from 'moment/moment';
import KoobButton from '../../../components/Koob/KoobButton';
import ReceiptNotes from './Partials/ReceiptNotes';
import { useTranslation } from 'react-i18next';
import { fetchTripReceiptData } from './utilities';

export default function TripReceipt() {
  const { tripId } = useParams();
  const { t } = useTranslation('trips');

  const [searchParams] = useSearchParams();

  const [processingExport, setProcessingExport] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(true);

  const { data: tripData, isLoading: tripLoading } = useAsync({
    promiseFn: getTrip,
    tripId,
    versionId: searchParams.get('version'),
  });
  const trip = tripData?.data;
  const travelersCount = (trip?.adultPax + trip?.childrenBirthdates.length) ?? trip?.maxPax;

  useEffect(() => {
    if (trip) {
      setBookingsLoading(true);

      fetchTripReceiptData({
        trip
      }).then(({ bookings, experiences, hotels }) => {
        setBookings(bookings);
        setExperiences(experiences);
        setHotels(hotels);
        setBookingsLoading(false);
      })
    }
  }, [trip]);

  const loading = tripLoading || bookingsLoading || processingExport;

  const bookedItems = bookings
    ?.sort((a, b) => moment(a.startAt).unix() - moment(b.startAt).unix())
    ?.reduce((acc, booking) => {
      if (booking?.experiencesBooked?.length) {
        const experience = booking.experiencesBooked[0];

        acc.push({
          id: booking.bookingId,
          type: experience.experience?.type,
          name: experience.experience?.name,
          location: `${experience?.experience?.inCity?.title}${experience?.experience?.inCity?.country?.alpha2 ? ', ' + experience?.experience?.inCity?.country?.alpha2 : ''}`,
          cancellationPolicy: experience.experience?.cancellationPolicies,
          state: booking.state,
          startAt: booking.startAt,
          endAt: booking.endAt,
          pax: experience.travelers.length,
          price: parseFloat(experience.price) - experience.extras?.reduce((acc, extra) => acc + parseFloat(extra.price), 0),
          currency: booking.currency,
        });

        experience?.extras?.forEach((extra) => {
          acc.push({
            id: extra.id,
            type: 'extra',
            name: extra.name,
            state: extra.state,
            startAt: moment(booking.startAt).add(extra.dayIndex, 'days').format('YYYY-MM-DD'),
            endAt: moment(booking.startAt).add(extra.dayIndex, 'days').format('YYYY-MM-DD'),
            pax: extra.adultPax + extra.childrenBirthdates.length,
            price: parseFloat(extra.price),
            currency: booking.currency,
          })
        });
      }

      if (booking?.hotel) {
        const hotel = booking.hotel;

        acc.push({
          id: booking.bookingId,
          type: 'hotel',
          name: hotel.displayName,
          location: `${hotel.city?.title}, ${hotel.city?.country?.title}`,
          cancellationPolicy: hotel.cancelationPolicies,
          state: booking.state,
          startAt: booking.startAt,
          endAt: booking.endAt,
          pax: booking.roomsBooked?.reduce((acc, room) => acc + room.travelers.length, 0),
          price: booking.roomsBooked?.reduce((acc, room) => acc + parseFloat(room?.room?.bedChoice?.totalPriceWithPromo), 0),
          cancellationFee: parseFloat(booking.cancellationFees),
          currency: booking.currency
        });
      }

      return acc;
    }, [])
    ?.filter((item) => !!item && !['replaced'].includes(item.state));

  const draftItems = trip?.tripDays?.reduce((acc, day) => {
    if (day.experience) {
      const experience = experiences.find((exp) => exp.id === day.experience.id);

      if (experience && day.experienceDayIndex === 0) {
        acc.push({
          id: experience.id,
          type: experience.type,
          name: experience.name,
          location: `${experience.inCity.title}${experience.inCity.country.alpha2 ? ', ' + experience.inCity.country.alpha2 : ''}`,
          cancellationPolicy: experience.cancellationPolicies,
          state: 'draft',
          startAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
          endAt: moment(trip.startDate).add(day.dayIndex - 2 + day.experience.durationDays, 'days').format('YYYY-MM-DD'),
          pax: travelersCount,
          price: experience?.price,
          currency: experience?.currency,
        });
      }

      day.options?.forEach((option) => {
        const experience = experiences.find((exp) => exp.id === option.optionId);

        if (experience) {
          acc.push({
            id: experience.id,
            type: 'extra',
            name: experience.name,
            state: 'draft',
            startAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
            endAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
            pax: travelersCount,
            price: experience?.price,
            currency: experience?.currency,
          });
        }
      });

      trip.globalOptions?.forEach((option) => {
        if (experience?.extras?.some(e =>
          e.id === option.optionId
          || e.required?.some(r => r.id === option.optionId)
          || e.suggested?.some(s => s.id === option.optionId)
        )) {
          const experience = experiences.find((exp) => exp.id === option.optionId);

          if (experience) {
            acc.push({
              id: experience.id,
              type: 'extra',
              name: experience.name,
              state: 'draft',
              startAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
              endAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
              pax: travelersCount,
              price: experience?.price,
              currency: experience?.currency,
            });
          }
        }
      });
    }

    const program = experiences?.find((exp) => exp.id === day.experience.id)?.programs?.[day.experienceDayIndex];

    if (day.hotelId && !program?.isIncludedAccomodation) {
      const hotel = hotels.find((h) => h.id === day.hotelId);

      if (hotel) {
        acc.push({
          id: hotel.id,
          type: 'hotel',
          name: hotel.displayName,
          location: `${hotel.city.title}, ${hotel.country.title}`,
          cancellationPolicy: hotel.cancelationPolicies,
          state: 'draft',
          startAt: moment(trip.startDate).add(day.dayIndex - 1, 'days').format('YYYY-MM-DD'),
          endAt: moment(trip.startDate).add(day.dayIndex, 'days').format('YYYY-MM-DD'),
          pax: travelersCount,
          price: hotel?.price,
          currency: hotel?.currency,
        });
      }
    }

    return acc;
  }, []);

  const items = (bookedItems?.length ? bookedItems : draftItems) ?? [];

  const exportToPdf = async () => {
    setProcessingExport(true);

    const html = prepareDocumentForHtmlToPdf(document.cloneNode(true));

    await htmlToPdf({
      html,
      options: {
        scale: 0.8,
        margin: {
          top: '1.5cm',
          bottom: '1.5cm',
          left: '0cm',
          right: '0cm',
        },
      },
      filename: `trip-receipt-${trip?.id}-${trip?.name?.replace(/\s+/g, '-')?.toLowerCase()}`
    });

    setProcessingExport(false);
  };

  // As per client request, we are not showing the cancellation policies
  const SHOW_CANCELLATION_POLICIES = false;

  return (
    <KoobLayout>
      {loading && (
        <ReceiptLoading />
      )}

      <KoobContainer>
        <div className="mb-3 max-w-6xl mx-auto flex justify-end">
          <KoobButton
            onClick={exportToPdf}
            loading={processingExport}
          >
            <i className="far fa-cloud-download mr-1" />
            {t('receipt.download')}
          </KoobButton>
        </div>

        <ReceiptWrapper>
          <ReceiptHeader />

          <ReceiptContent items={items} />

          <ReceiptNotes />

          {SHOW_CANCELLATION_POLICIES && (
            <ReceiptCancellationPolicies items={items} />
          )}
        </ReceiptWrapper>
      </KoobContainer>
    </KoobLayout>
  )
}
