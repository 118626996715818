import ExportSection from './ExportSection';
import ExportDayPointsOfInterestList from './ExportDayPointsOfInterestList';
import moment from 'moment';
import ExportDayPointsOfInterestsMap from './ExportDayPointsOfInterestsMap';
import React from 'react';
import ExportDayHotel from './ExportDayHotel';
import ExportDayHotelBookedOnMyOwn from './ExportDayHotelBookedOnMyOwn';
import ExportDaySection from './ExportDaySection';
import ExportDaySectionContent from './ExportDaySectionContent';
import ExportDaySectionTitle from './ExportDaySectionTitle';
import ExportDayMeal from './ExportDayMeal';
import ExportDayOptions from './ExportDayOptions';
import { ProductTypeEnum } from '@koob/enums';

export default function ExportDay({ trip, day, index, tourOperator, experience }) {
  const program = experience?.programs[day.experienceDayIndex];
  const dayData = {trip, tourOperator, day, index, experience, program};

  const hotelDayBookings = trip?.bookings
  .filter((booking) => booking.hotelId === day?.hotelId)
  .sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
  const currentHotelBooking = hotelDayBookings[Math.min(index, hotelDayBookings.length - 1)];
  const isIncludedHotel =  program?.accomodationType === ProductTypeEnum.HOTEL
    && program?.isIncludedAccomodation
    && (
      program?.accomodationHotel?.id === day.hotelId
      || program?.alternativeHotels?.some(hotel => hotel.id === day.hotelId)
    );
  const bookingId = !isIncludedHotel ? currentHotelBooking?.id : undefined

  const experienceBookings = trip?.bookings.reduce((acc, booking) => {
    booking.experiencesBooked.forEach(experienceBooked => {
      if (experienceBooked?.experience?.id === experience?.id) {
        acc.push(booking);
      }
    });
    return acc;
  }, []);

  const { startAt, endAt } = experienceBookings[Math.min(index, experienceBookings.length - 1)] ?? {};
  const experienceBookingPeriod = startAt && endAt
    ? `${moment(startAt).format('DD/MM/YYYY')} — ${moment(endAt).format(
        'DD/MM/YYYY',
      )}`
    : moment(trip?.startDate).add(index, 'days').format('DD/MM/YYYY');

  const dayPicture = program?.picture ?? experience?.pictures?.sort((a, b) => a.isMainPicture - b.isMainPicture)?.[0];

  return (
    <>
      <ExportDaySection dayData={dayData}>
        <ExportDaySectionContent images={[dayPicture]}>
          <div className="-mt-12">
            <ExportDaySectionTitle>
              <i className="fa-regular fa-notebook mr-2"/>
              Program of the Day
            </ExportDaySectionTitle>

            <div dangerouslySetInnerHTML={{__html: program?.program}}/>
          </div>

          {program?.meals?.length > 0 && (
            <div>
              <ExportDaySectionTitle>
                <i className="fa-regular fa-user-chef mr-2"/>
                Meals
              </ExportDaySectionTitle>

              <ExportDayMeal meals={program?.meals}/>
            </div>
          )}

          {program?.startPosition && (
            <div>
              <ExportDaySectionTitle>
                <i className="fa-regular fa-flag mr-2"/>

                {!program?.startPosition?.isCity ? (
                  program?.startPosition?.name ?? 'Start Position'
                ) : (
                  'Start Position'
                )}
              </ExportDaySectionTitle>

              <div className="text-gray-600">
                {program?.startPosition?.address}
              </div>
            </div>
          )}

          {program?.endPosition && (
            <div>
              <ExportDaySectionTitle>
                <i className="fa-regular fa-flag-checkered mr-2"/>

                {!program?.endPosition?.isCity ? (
                  program?.endPosition?.name ?? 'End Position'
                ) : (
                  'End Position'
                )}
              </ExportDaySectionTitle>

              <div className="text-gray-600">
                {program?.endPosition?.address}
              </div>
            </div>
          )}
        </ExportDaySectionContent>
      </ExportDaySection>

      {program?.pois?.length > 0 && (
        <ExportDaySection dayData={dayData}>
          <div className="grid grid-cols-2 gap-16">
            <div>
              <ExportDayPointsOfInterestsMap
                pois={program?.pois}
              />
            </div>

            <div>
              <ExportDaySectionTitle>
                <i className="fa-regular fa-panorama mr-2"/>
                Points of Interest
              </ExportDaySectionTitle>

              <ExportDayPointsOfInterestList
                pois={program?.pois}
              />
            </div>
          </div>
        </ExportDaySection>
      )}

      {day?.hotelId && (
        <ExportDaySection dayData={dayData}>
          <ExportDayHotel
            hotelId={day.hotelId}
            bookingId={bookingId}
            dayData={dayData}
            isIncluded={isIncludedHotel}
          />
        </ExportDaySection>
      )}

      {day.hotelBookedOnMyOwn && (
        <ExportDaySection dayData={dayData}>
          <ExportDayHotelBookedOnMyOwn />
        </ExportDaySection>
      )}

      {(day.options?.length > 0) && (
        <ExportDaySection dayData={dayData}>
          <ExportDayOptions
            options={day.options}
          />
        </ExportDaySection>
      )}

      {(day.experienceDayIndex === experience?.durationDays - 1 && (experience?.includedServices || experience?.excludedServices)) && (
        <ExportSection
          title={`${experience?.name}`}
          subtitle={`${!trip?.template ? `${experienceBookingPeriod} — ` : ''}${program?.startPosition?.city}, ${program?.startPosition?.country}`}
          trip={trip}
          tourOperator={tourOperator}
        >
          <div className="grid grid-cols-2 gap-16">
            <div className="bg-gray-100 p-8 rounded-lg">
              <ExportDaySectionTitle>
                <i className="fa-regular fa-circle-plus mr-1"/> Included Services
              </ExportDaySectionTitle>

              <div dangerouslySetInnerHTML={{__html: experience?.includedServices}}/>
            </div>

            <div className="bg-gray-100 p-8 rounded-lg">
              <ExportDaySectionTitle>
                <i className="fa-regular fa-circle-minus mr-1" /> Excluded Services
              </ExportDaySectionTitle>

              <div dangerouslySetInnerHTML={{__html: experience?.excludedServices}}/>
            </div>
          </div>
        </ExportSection>
      )}
    </>
  )
}
