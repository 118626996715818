import { HotelBookingPolicies } from '@koob/booking';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getRoomsCompositionPolicies } from '../../../../api/hotels';
import SupplementTypes from '../../../../constants/SupplementType';

const HotelBookingPoliciesWrapper = ({ requestId, rooms }) => {
  const { t } = useTranslation('booking');

  const { data: policies, isLoading } = useAsync(
    {
      promiseFn: getRoomsCompositionPolicies,
      policiesRequest: {
        requestId: requestId,
        roomsToBook: rooms?.map((elem, index) => {
          const promotionInfo = rooms?.[index]?.bed?.promotionInfo;
          const isSupplementPromo =
            promotionInfo?.kind &&
            !SupplementTypes.includes(promotionInfo?.kind);
          const promoId = isSupplementPromo
            ? promotionInfo?.promotionId
            : undefined;

          return {
            hotelRoomId: elem?.supplierSpecificInformations?.hotelRoomId,
            bedCompositionId: elem.bed.id,
            promotionId: promoId,
          };
        }),
      },
    },
    false,
  );

  const policiesData = {
    displayName: t('contractingConditions'),
    from: t('room.from'),
    to: t('room.to'),
    cancellationFees: t('room.cancellationFees'),
  };

  return (
    <>
      <HotelBookingPolicies
        isLoading={isLoading}
        translationData={policiesData}
        policies={policies?.data}
      />
    </>
  );
};

export default HotelBookingPoliciesWrapper;
