import { Stack } from '@koob/margaret';
import { MdMap } from 'react-icons/md';
import { IoFlag } from 'react-icons/io5';
import styled from 'styled-components';
import LocationSearchableSelect from '../Experience/LocationSearchableSelect';
import { searchLocations } from 'api/locations';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTripDesigner } from '../../hooks';
import { useAsync } from 'react-async';
import { Button, SearchableSelect } from 'components';
import { getOrganizationByTo } from 'api/requestConnection';
import PriceRangeModal from 'containers/TripDesigner/Compose/Partials/PriceRangeModal';
import { MdAlarm } from 'react-icons/md';
import KoobModal from '../Koob/KoobModal';
import LocalMultiSearchableSelectField from '../Fields/LocalMultiSearchableSelectField';
import { getAllCountries } from '../../api/countries';
import { Form, Formik } from 'formik';
import KoobButton from '../Koob/KoobButton';
import KoobTitle from 'components/Koob/KoobTitle';
import { useTheme } from 'styled-components';
import { getThemes } from 'api/themes';
import { getSegments } from 'api/segments';
import { getOrgUsers } from 'api/users';
import { useApp } from 'hooks';
import KoobSearchBar from '../Koob/KoobSearchBar';
import { FormLabel } from 'ui';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';

const CustomStack = styled(Stack)`
  margin-bottom: 10px;
  min-height: 50px;
  background: white;
  border-top: 5px solid #f6800b;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
`;

const LibraryFilter = ({ isDmcTrips, tags }) => {
  const listMode = 'list';
  const HEIGHT = 42;
  const {t} = useTranslation('tripDesigner');
  const {libraryFilters, setLibraryFilters} = useTripDesigner();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rangeModalShown, setRangeModalShown] = useState(false);
  const [showCountriesModal, setShowCountriesModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [cities, setCities] = useState([]);
  const theme = useTheme();
  const {data: themesData} = useAsync({promiseFn: getThemes});
  const {data: segmentsData} = useAsync({promiseFn: getSegments});

  const {currentOrganizationIdInt} = useApp();
  const {data: usersData} = useAsync({
    promiseFn: currentOrganizationIdInt ? getOrgUsers : undefined,
    orgId: currentOrganizationIdInt,
    watch: currentOrganizationIdInt
  })
  const users = usersData?.data

  const {data} = useAsync({promiseFn: getOrganizationByTo});
  const datas =
    (data?.data || [])
      .map(dmc => ({
        value: dmc.id,
        label: dmc?.displayName,
      }));

  const {data: countriesData} = useAsync({
    promiseFn: getAllCountries
  });
  const countries = (countriesData?.data || []);

  const updateFilterValue = useCallback(
    (property, value) => {
      setLibraryFilters(current => ({
        ...current,
        [property]: value,
      }));
      if (property === 'onlyFavorites') {
        setSearchParams({...searchParams, onlyFavorites: value});
      }
    },
    [setLibraryFilters],
  );

  useEffect(() => {
    if (searchParams.has('onlyFavorites')) {
      updateFilterValue('onlyFavorites', searchParams.get('onlyFavorites') === 'true');
    }
  }, []);

  return (
    <CustomStack
      alignY="start"
      size="full"
      gutterSize={1}
      listMode={listMode}
    >
      <div className="flex flex-wrap gap-2 items-center">
        <KoobSearchBar
          placeholder={t('search.search')}
          value={libraryFilters?.search}
          onChange={value => updateFilterValue('search', value)}
          size="small"
        />

        <Button
          onClick={() => {
            setShowCountriesModal(true);
          }}
          variant="simple"
          style={{width: 'auto', height: HEIGHT, borderRadius: '20px'}}
        >
          <Stack gutterSize={1}>
            <Stack gutterSize={0.375} alignY="center">
              <MdMap size="18" color="grey"/>
              <span>
                {t('library.form.locations')}
              </span>
            </Stack>
          </Stack>
        </Button>

        <Button
          onClick={() => {
            setRangeModalShown(true);
            setModalType('tripDuration');
          }}
          variant="simple"
          style={{width: 'auto', height: HEIGHT, borderRadius: '20px'}}
        >
          <Stack gutterSize={1}>
            <Stack gutterSize={0.375} alignY="center">
              <MdAlarm size="18" color="grey"/>
              <span>
                {libraryFilters?.tripDuration ? t('search.expDurationCount', {
                    count: libraryFilters?.tripDuration
                  })
                  : isDmcTrips ? t('search.duration') : t('search.tripDuration')}
              </span>
            </Stack>
          </Stack>
        </Button>

        <SearchableSelect
          placeholder={t('search.dmcName')}
          renderSelectedOption={value => value?.label}
          datas={datas}
          value={libraryFilters.dmcId}
          onChange={value => updateFilterValue('dmcId', value)}
          setValue={() => {
          }}
          locale
        />

        {!isDmcTrips && (
          <SearchableSelect
            placeholder={t('search.user')}
            renderSelectedOption={value => value?.firstName + ' ' + value?.lastName}
            datas={users}
            value={libraryFilters.users}
            onChange={value => updateFilterValue('users', value)}
            setValue={() => {
            }}
            locale
          />
        )}

        <Stack alignY="center" gutterSize={1}>
          <Select
            placeholder={t('search.experienceTheme')}
            name="themes"
            options={themesData?.data}
            getOptionValue={value => value?.id}
            getOptionLabel={value => value?.displayName}
            onChange={value => updateFilterValue('themes', value)}
            isMulti
            isSearchable={false}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderRadius: '20px',
                height: HEIGHT,
                padding: '0 0.5rem',
                border: `1px solid ${theme.separator}`,
              }),
            }}
          />
        </Stack>

        <Stack alignY="center" gutterSize={1}>
          <Select
            placeholder={t('search.experienceSegment')}
            name="segments"
            options={segmentsData?.data}
            getOptionValue={value => value?.id}
            getOptionLabel={value => value?.displayName}
            onChange={value => updateFilterValue('segments', value)}
            isMulti
            isSearchable={false}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderRadius: '20px',
                height: HEIGHT,
                padding: '0 0.5rem',
                border: `1px solid ${theme.separator}`,
              }),
            }}
          />
        </Stack>

        <Button
          onClick={() => {
            setShowTagsModal(true);
          }}
          variant="simple"
          style={{width: 'auto', height: HEIGHT, borderRadius: '20px'}}
        >
          <Stack gutterSize={1}>
            <Stack gutterSize={0.375} alignY="center">
              <i className="far fa-tags text-gray-500 mr-1" />
              <span>
                {t('library.form.tags')}
              </span>
            </Stack>
          </Stack>
        </Button>

        <LocationSearchableSelect
          placeholder={t('designTripModal.form.departureLocation')}
          initialValue={libraryFilters?.departureLocation?.[0]}
          query={searchLocations}
          renderOption={value => value.kind === 'city' ? value?.title : null}
          renderSelectedOption={value => value?.title}
          onChange={value => value !== '' ? updateFilterValue('departureLocation', [value]) : updateFilterValue('departureLocation', [])}
          wrapperStyle={{
            marginLeft: 0,
            minWidth: 100,
            height: HEIGHT,
            '--location-search-trigger-height': `${HEIGHT}px`,
          }}
          kinds={["city"]}
          inputStyle={{minWidth: 100, height: HEIGHT, maxWidth: 150}}
        />

        <LocationSearchableSelect
          placeholder={t('designTripModal.form.returnLocation')}
          initialValue={libraryFilters?.returnLocation?.[0]}
          query={searchLocations}
          renderOption={value => value.kind === 'city' ? value?.title : null}
          renderSelectedOption={value => value?.title}
          onChange={value => value !== '' ? updateFilterValue('returnLocation', [value]) : updateFilterValue('returnLocation', [])}
          wrapperStyle={{
            marginLeft: 0,
            minWidth: 100,
            height: HEIGHT,
            '--location-search-trigger-height': `${HEIGHT}px`,
          }}
          kinds={["city"]}
          inputStyle={{minWidth: 100, height: HEIGHT, maxWidth: 150}}
        />

        <Button
          variant={libraryFilters?.guided ? 'primary' : 'simple'}
          style={{width: 150, height: HEIGHT, borderRadius: '20px'}}
          onClick={() => updateFilterValue('guided', !libraryFilters?.guided ?? true)}>
          <Stack gutterSize={1}>
            <Stack gutterSize={0.375} alignY="center">
              <IoFlag size={18}/>
              <span>
                  {libraryFilters?.guided ? t('search.guided') : t('search.notGuided')}
              </span>
            </Stack>
          </Stack>
        </Button>

        {!isDmcTrips && <Button
          variant={libraryFilters?.onlyFavorites ? "primary" : "simple"}
          style={{ width: 150, height: HEIGHT, borderRadius: '20px' }}
          onClick={() => updateFilterValue('onlyFavorites', !libraryFilters?.onlyFavorites)}
        >
          <Stack gutterSize={1}>
            <Stack gutterSize={0.375} alignY="center">
              <span>
                {libraryFilters?.onlyFavorites ? t('search.onlyFavorites') : t('search.withFavorites')}
              </span>
            </Stack>
          </Stack>
        </Button>}

        {libraryFilters?.type === 'template' && (
          <Button
            variant={libraryFilters?.withArchived ? 'primary' : 'simple'}
            style={{width: 150, height: HEIGHT, borderRadius: '20px'}}
            onClick={() => updateFilterValue('withArchived', !libraryFilters?.withArchived)}
          >
            <Stack gutterSize={1}>
              <Stack gutterSize={0.375} alignY="center">
                <span>
                  {libraryFilters?.withArchived ? t('misc:archived') : t('misc:noArchived')}
                </span>
              </Stack>
            </Stack>
          </Button>
        )}
      </div>

      {rangeModalShown && (
        <PriceRangeModal
          open={rangeModalShown}
          setOpen={setRangeModalShown}
          mode={modalType}
          initialesValues={[0, libraryFilters?.tripDuration]}
          min={0}
          max={50}
          onSave={(value) => updateFilterValue('tripDuration', value[1])}
        />
      )}

      {showCountriesModal && (
        <KoobModal
          open={showCountriesModal}
          setOpen={setShowCountriesModal}
          overflow="visible"
        >
          <div className="px-5">
            <Formik
              initialValues={{
                countries: libraryFilters.countries,
                cities: libraryFilters.cities
              }}
              onSubmit={values => {
                updateFilterValue('countries', values.countries);
                updateFilterValue('cities', cities);
                setShowCountriesModal(false);
              }}
            >
              <Form>
                <KoobTitle className="text-center" size="text-2xl">{t('library.form.locations')}</KoobTitle>
                <div className="flex flex-col space-y-4 my-4">
                  <LocalMultiSearchableSelectField
                    label={t('designTripModal.form.countries')}
                    name={`countries`}
                    options={
                      countries?.map(country => ({value: country.id, label: country.title}))
                    }
                    renderOption={option => option.label}
                    renderSelectedOption={option => option.label}
                  />

                  <FormLabel>
                    {t('designTripModal.form.cities')}
                  </FormLabel>
                  <div className="w-full border shadow my-4 p-2">
                    <div className="flex flex-col space-y-2 p-2">
                      {cities?.map((city, index) => (
                        <div
                          className="flex justify-between items-center"
                          key={index}
                        >
                          <div>{city?.title}</div>

                          <button
                            type="button"
                            className="border-none text-red-500"
                            onClick={() => {
                              setCities(cities.filter((_, i) => i !== index));
                            }}
                          >
                            <svg className="h-4 w-4" viewBox="0 0 384 512">
                              <path
                                d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/>
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                    <LocationSearchableSelect
                      placeholder={t('search.search')}
                      query={searchLocations}
                      onChange={value => setCities([...cities, value])}
                      wrapperStyle={{
                        marginLeft: 0,
                        minWidth: 100,
                        height: HEIGHT,
                        '--location-search-trigger-height': `${HEIGHT}px`,
                      }}
                      kinds={["city"]}
                      inputStyle={{minWidth: 100, height: HEIGHT}}
                      clearAfterChange
                    />
                  </div>
                </div>

                <KoobButton type="submit" block>
                  {t('misc:continue')}
                </KoobButton>
              </Form>
            </Formik>
          </div>
        </KoobModal>
      )}

      {showTagsModal && (
        <KoobModal
          open={showTagsModal}
          setOpen={setShowTagsModal}
          overflow='visible'
        >
          <KoobTitle className="text-center" size="text-xl">
            {t('search.tripTagsModalTitle')}
          </KoobTitle>

          <div className="my-16 max-w-md mx-auto">
            <Select
              placeholder={t('search.tripTags')}
              name="tags"
              options={tags}
              getOptionValue={value => value?.id}
              getOptionLabel={value => value?.name}
              onChange={value => updateFilterValue('tags', value)}
              value={libraryFilters?.tags}
              isMulti
              isSearchable={false}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderRadius: '20px',
                  height: HEIGHT,
                  width: '100%',
                  padding: '0 0.5rem',
                  border: `1px solid ${theme.separator}`,
                }),
              }}
            />
          </div>

          <div className="flex justify-end">
            <KoobButton
              onClick={() => {
                setShowTagsModal(false);
              }}
              color="primary"
            >
              {t('misc:save')}
            </KoobButton>
          </div>
        </KoobModal>
      )}
    </CustomStack>
  );
};

export default LibraryFilter;
