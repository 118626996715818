import styled, { css, useTheme } from 'styled-components';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { media } from 'ui';
import { useBooking } from 'hooks';
import { Stack } from '@koob/margaret';
import Select from 'react-select';
import { PromotionTag, RequiredPromotionTag, KoobButton } from '@koob/ui';

const RoomExtraContent = styled(Stack)`
    padding-left: 0;
    padding-right: ${({theme}) => theme.spacing()};

    ${media.tablet`
    padding-left: ${({theme}) => theme.spacing()};
  `}
    p {
        margin-top: ${({theme}) => theme.spacing(0.5)};
        margin-bottom: ${({theme}) => theme.spacing(0.5)};
    }
`;

export const Promotion = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
  marginBottom: 0.5,
})`
    font-size: 0.75rem;
    padding: ${({theme}) => theme.spacing(0.25)} ${({theme}) => theme.spacing(0.75)};
    border-radius: 40px;
    color: #2e1065;
    background-color: ${({bgColor}) => bgColor ?? '#e9d5ff'};

    ${props =>
            props.promo &&
            css`
                padding: ${({theme}) => theme.spacing(0.2)};
                margin-top: -1px !important;
            `
    }
`;

export const BedTag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
    cursor: pointer;
    border: 1px solid ${({theme}) => theme.separator};
    padding: 3px 10px;
    ${props =>
            props.selected &&
            css`
                background: ${({theme}) => theme.orangeLight2};
                color: ${({theme}) => theme.orange};
            `}
    ${props =>
            props.red &&
            css`
                background-color: #ffe3e3;
                color: #ea5d5d !important;
            `}
    ${props =>
            props.grey &&
            css`
                color: rgba(0, 0, 0, 0.5);
                background-color: #eeeeee;
            `}
    ${props =>
            props.green &&
            css`
                background: rgba(115, 201, 146, 0.12);
                color: #73c992;
            `}

    border-radius: 5px;
`;


const Separator = styled.div`
    width: 100%;
    border-top: 1px solid ${({theme}) => theme.separator};
    margin-top: ${({theme}) => theme.spacing(1)};
    margin-bottom: ${({theme}) => theme.spacing(1)};
`;

export function RoomCard({
  possibility,
  currentBed,
  currentBedIndex,
  changeSelectedBed,
  isSelected,
  isRoomSelected,
  deleteRoom,
  promotions,
  selectedRoomsFromPromotionSelect,
  setSelectedPromotionSupplements,
  selectedPromotionSupplements
}) {
  const {
    selectedSupplements,
    setSelectedSupplements,
    currentSelectedRoom,
    setCurrentSelectedRoom,
    selectedRooms
  } = useBooking();
  const { t } = useTranslation('booking', {keyPrefix: 'room'});
  const theme = useTheme();
  const HEIGHT = 42;

  const [values, setValues] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState(0);
  const [selectedPromotionSupp, setSelectedPromotionSupp] = useState();
  const [clicked, setClicked] = useState(false);

  const selectedRoomsCount = selectedRooms.length ?? 0;
  let promotionIndex = 0;
  const promotionOptions =
    possibility.allPromotions
      ?.flatMap(promotionCombinations => {
        return Object.entries(promotionCombinations).map(
          ([key, promotionCombination]) => {
            if (
              currentBed &&
              currentBed.id.split('##').pop() === key.split('##').pop() &&
              promotionCombination.length
            ) {
              const result = {
                label: promotionCombination.join(', '),
                value: key,
                index: promotionIndex,
                promotions: promotionCombination,
              };
              promotionIndex++;
              return result;
            } else {
              return null;
            }
          },
        );
      }).filter(item => item !== null) ?? [];

  const promotionOptionsFiltered = (promotionOptions || [])
    .filter(item => !promotions.some(sup => sup.value === item.label));

  const koediaPromos = currentBed?.promotions
    ?.filter(item => item !== null);

  const filteredOptionSupplement = uniqBy(promotions.filter(({value}) => {
    return possibility.bedChoices[0].optionalSupplements?.some(item => item.name === value);
  }), 'displayName');

  const handleClick = (e) => {
    if (clicked) {
      e.stopPropagation();
    }else{
      setClicked(true);
    }
  };

  const renderPromotions = () => {
    const filteredPromotions = isRoomSelected
      ? currentBed?.promotions
      : promotionOptions[selectedPromotion]?.promotions;

    const supplements = currentBed?.totalPriceWithoutPromo !== currentBed?.totalPriceWithPromo
      ? currentBed?.mandatorySupplements
      : [];

    if (currentBed?.promotions?.length > 0 || supplements?.length > 0) {
      return (
        <RoomExtraContent size="full" direction="column" gutterSize={0.5}>
          <Separator/>

          <Stack
            style={{
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {filteredPromotions?.map((name, index) => (
              <PromotionTag key={index} name={name}/>
            ))}

            {supplements?.map((name, index) => (
              <RequiredPromotionTag
                label={t('booking:summary:requiredSupplement')}
                key={index}
                name={name}
              />
            ))}
          </Stack>
        </RoomExtraContent>
      );
    }
  }

  useEffect(() => {
    if (isSelected && selectedPromotion !== 0) {
      setSelectedPromotion(values?.index ?? selectedPromotion);
      setClicked(false);
    }
    if (isRoomSelected && selectedPromotionSupp?.length > 0) {
      setSelectedSupplements([]);
      setSelectedPromotionSupp(null)
    }
    if (isSelected && selectedRoomsFromPromotionSelect) {
      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, currentBed?.name)
    }
  }, [currentBedIndex, isSelected]);

  useEffect(() => {
    setSelectedPromotion(0);
    if (selectedPromotionSupp?.length > 0) {
      setSelectedSupplements([]);
    }
  }, [isSelected])

  useEffect(() => {
    if (!isSelected && selectedRoomsFromPromotionSelect) {
      setClicked(false);
      setSelectedPromotion(values?.index ?? 0)
      selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, currentBed?.name)
    }
  }, [selectedPromotion, values]);

  useEffect(() => {
    if (!selectedPromotion) {
      const promosPrice = selectedSupplements?.reduce((total, {price}) => total + price, 0)

      setCurrentSelectedRoom({
        ...currentSelectedRoom,
        bed: {
          ...currentSelectedRoom?.bed,
          totalPriceWithPromo: currentSelectedRoom?.bedChoices?.[0]?.totalPriceWithPromo + promosPrice,
        },
        optionalPromos: selectedSupplements
      });
    }

  }, [selectedSupplements]);

  const selectedPromo = promotionOptionsFiltered?.find(item => {
    return item.label === selectedRooms?.[selectedRooms?.length - 1]?.bed?.promotions?.[0];
  });

  return (
    <div className="flex-col space-y-3">
      {(Array.isArray(possibility.allPromotions) === true &&
        possibility.allPromotions.length > 1 &&
        promotionOptionsFiltered.length > 1) && (
          <div>
            <div className="mb-1.5 text-lg font-medium">
              <i className="fal fa-tag mr-1.5"/>
              {t('choosePromotion')}
            </div>

            <div onClick={handleClick}>
              <Select
                onMenuOpen={() => {
                  setClicked(true);
                  if (!isSelected && selectedRoomsFromPromotionSelect) {
                    setSelectedPromotion(values?.index);
                    selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, currentBed.name)
                  }
                  setClicked(false);
                }}
                placeholder={'Select a promotions'}
                name="promotions"
                inputId={!isRoomSelected && `select-promotion-${selectedRooms.length}`}
                options={promotionOptionsFiltered}
                defaultValue={promotionOptionsFiltered[0]}
                value={
                  isRoomSelected
                    ? selectedPromo
                    : promotionOptionsFiltered[selectedPromotion]
                }
                onChange={(value) => {
                  setValues(value);
                  setSelectedPromotion(value.index);
                  if (selectedRoomsFromPromotionSelect) {
                    selectedRoomsFromPromotionSelect(possibility.originalIndex, value.label, currentBed.name)
                  }
                }}
                isDisabled={isRoomSelected}
                isClearable={false}
                styles={{
                  control: baseStyles => ({
                    ...baseStyles,
                    borderRadius: '5px',
                    height: HEIGHT,
                    width: filteredOptionSupplement?.length > 0 ? 'fit-content' : '350px',
                    minWidth: '350px',
                    padding: '0 0.5rem',
                    border: `1px solid ${theme.separator}`,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? 'transparent' : 'transparent',
                    color: state.isSelected ? 'default color' : 'default color',
                    ':hover': {
                      backgroundColor: 'color when option is hovered'
                    }
                  }),
                }}
              />
            </div>
          </div>
        )
      }

      {(Array.isArray(filteredOptionSupplement) === true && filteredOptionSupplement?.length > 0) && (
        <div>
          <div className="mb-1.5 text-lg font-medium">
            <i className="fal fa-tag mr-1.5"/>
            {t('chooseOptionalPromotion')}
          </div>

          <div onClick={handleClick}>
            <Select
              key={possibility.originalIndex}
              placeholder={'Select supplements'}
              name="promotions"
              inputId={!isRoomSelected && `select-supplement-${selectedRooms.length}`}
              {...(isRoomSelected ? { defaultValue: selectedSupplements } : {})}
              value={
                isRoomSelected
                  ? selectedPromotionSupplements?.[possibility.originalIndex]
                  : selectedPromotionSupp
              }
              options={filteredOptionSupplement}
              getOptionValue={value => value?.id}
              getOptionLabel={value => value?.displayName}
              onMenuOpen={() => {
                setClicked(true);
                if (!isSelected && selectedRoomsFromPromotionSelect) {
                  setSelectedPromotion(values?.index);
                  selectedRoomsFromPromotionSelect(possibility.originalIndex, values?.label, currentBed.name)
                }
                setClicked(false);
              }}
              onChange={value => {
                setSelectedSupplements(value);
                setSelectedPromotionSupplements(prev => {
                  return {
                    ...prev,
                    [possibility.originalIndex]: value
                  }
                });
              }}
              isDisabled={isRoomSelected}
              isSearchable={false}
              isClearable={false}
              isMulti
              className="basic-multi-select"
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  borderRadius: '5px',
                  padding: '0 0.5rem',
                  border: `1px solid ${theme.separator}`,
                  width: (selectedSupplements?.length > 0 || isRoomSelected) ? 'fit-content' : '350px',
                  minWidth: '350px',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused ? 'transparent' : 'transparent',
                  color: state.isSelected ? 'default color' : 'default color',
                  ':hover': {
                    backgroundColor: 'color when option is hovered'
                  }
                }),
              }}
            />
          </div>
        </div>
      )}

      {renderPromotions()}

      {possibility?.supplier === 'Koedia' && koediaPromos?.length > 0 && (
        <div className="flex flex-wrap gap-3">
          {koediaPromos?.map((name, index) => (
            <PromotionTag key={index} name={name}/>
          ))}
        </div>
      )}

      {(possibility.bedChoices ?? []).length > 1 && (
        <div>
          <div className="mb-1.5 text-lg font-medium">
            <i className="fal fa-bed mr-1.5"/>
            {t('selectedComposition')}
          </div>

          <div className="flex flex-wrap gap-3">
            {(possibility?.bedChoices ?? []).map((bedElement, index) => (
              <BedTag
                key={index}
                onClick={e => {
                  e.stopPropagation();
                  if (currentBedIndex !== index) {
                    changeSelectedBed(index);
                  }
                }}
                selected={currentBed?.id === bedElement?.id}
                >
                  <div 
                    data-cy={
                      !isRoomSelected &&
                      `${bedElement.name?.toLowerCase().includes('extra bed') ? 'extra_bed' : 'koob_bed'}_${selectedRoomsCount}`
                    }
                  >
                    {bedElement.name}
                  </div>
              </BedTag>
            ))}
          </div>
        </div>
      )}

      {isRoomSelected && (
        <Stack size="full" alignX="end">
          <KoobButton
            type="button"
            onClick={() => {
              deleteRoom();
            }}
          >
            <i className="far fa-pen mr-1.5" />
            {t('update')}
          </KoobButton>
        </Stack>
      )}
    </div>
  );
}
