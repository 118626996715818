import { getExperience } from '../../api/experiences';
import { getHotel } from '../../api/hotels';
import { decodeId } from '../../utils';
import { ExtraScopeEnum } from '@koob/enums';

export async function formatTripFromApi(trip) {
  const tripDays = trip?.tripDays;

  const days = [];

  await Promise.all(tripDays?.map(async (day) => {
    const dayData = {};
    if (day.experienceId) {
      const experience = await getExperience({ experienceId: day.experienceId });
      dayData.experience = experience.data;
      dayData.experience.dayIndex = day.experienceDayIndex + 1;
    }

    if (day.hotelId) {
      const hotel = await getHotel({ hotelId: day.hotelId });

      const program = dayData?.experience?.programs?.[day.experienceDayIndex];

      dayData.hotel = {
        ...hotel.data,
        id: decodeId(hotel.data.id),
        isIncluded: program?.isIncludedAccomodation ?? false,
      };
    }

    dayData.hotelBookedOnMyOwn = day.hotelBookedOnMyOwn ?? false;

    days.push({
      ...dayData,
      dayIndex: day.dayIndex
    });
  }));

  const allExtras = [
    ...(trip?.globalOptions?.map(item => ({ ...item, scope: ExtraScopeEnum.GLOBAL })) ?? []),
    ...(trip?.tripDays?.flatMap(day => day.options)?.map(item => ({ ...item, scope: ExtraScopeEnum.DAY })) ?? []),
  ]?.filter(Boolean);

  const initialExtras = [];

  await Promise.all(allExtras?.map(async option => {
    const experience = await getExperience({ experienceId: option.optionId });

    initialExtras.push({
      ...experience.data,
      parentExtraId: option?.parent?.option,
      dayIndex: option.dayIndex,
      scope: option.scope
    });
  }) ?? []);

  return {
    days: days.sort((a, b) => a.dayIndex - b.dayIndex),
    initialExtras
  };
}
