import { getBooking } from '../../../api/bookings';
import { getExperience, getExperiences } from '../../../api/experiences';
import { getHotel, getHotels } from '../../../api/hotels';
import moment from 'moment';

const formatPrice = (price, pax = 1) => {
  return (parseFloat(price) * pax).toFixed(2);
};

const getHotelPrice = async ({ hotelId, trip, dayIndex }) => {
  return new Promise((resolve) => {
    const requestFilters = {
      hotels: [hotelId],
      startAt: moment(trip.startDate).add(dayIndex, 'days').format('YYYY-MM-DD'),
      endAt: moment(trip.startDate).add(dayIndex + 1, 'days').format('YYYY-MM-DD'),
      rooms: [{
        adults: trip.maxPax,
        children: 0,
        childrenBirthdates: [],
        count: trip.maxPax,
        firstAdultNationality: ''
      }],
      cities: [],
      countries: [],
      kinds: [],
      regions: [],
      styles: [],
      sustainability: null,
    };

    const getHotelPrice = async () => {
      const hotelPrice = (await getHotels({ filters: requestFilters }))?.data;
      if (hotelPrice.state === 'inProgress') {
        setTimeout(() => getHotelPrice(), 3000);
      } else {
        resolve(hotelPrice?.data?.[0]);
      }
    };

    getHotelPrice();
  });
};

export const fetchTripReceiptData = async ({ trip }) => {
  const bookings = await Promise.all(
    trip.bookings.map(async (booking) => {
      if (!booking.experiencesBooked?.length) {
        return (await getBooking({ bookingId: booking.id }))?.data;
      } else {
        return booking;
      }
    })
  );

  let experiences = [];
  let hotels = [];

  if (bookings?.length === 0) {
    const newExperiences = await trip.tripDays?.reduce(async (acc, day, index) => {
      const accumulatedExperiences = await acc;

      if (day.experience) {
        if (day.experienceDayIndex === 0) {
          const experienceData = (await getExperience({ experienceId: day.experience.id }))?.data;
          const experiencePrice = (await getExperiences({
            filters: {
              experiences: [day.experience.id],
              experienceComposition: {
                adults: trip?.adultPax ?? trip?.maxPax,
                children: trip?.childrenBirthdates.length ?? 0,
                childrenBirthdates: trip?.childrenBirthdates ?? [],
                count: trip?.adultPax + trip?.childrenBirthdates.length ?? trip?.maxPax,
                firstAdultNationality: ''
              },
              startAt: moment(trip.startDate).add(index, 'days').format('YYYY-MM-DD'),
              endAt: moment(trip.startDate).add(index + experienceData.durationDays, 'days').format('YYYY-MM-DD'),
              isForTripPrice: true
            }
          }))?.data?.[0]?.price;

          accumulatedExperiences.push({
            ...experienceData,
            priceObject: experiencePrice,
            price: experiencePrice?.price,
            currency: experiencePrice?.currency
          });
        }

        const experiencePrice = accumulatedExperiences.findLast((experience) => experience.id === day.experience.id);

        for (const option of day.options) {
          const optionData = (await getExperience({ experienceId: option.optionId }))?.data;
          const optionPrice = experiencePrice?.extras?.find((extra) => extra.id === option.optionId);
          const pricing = optionPrice?.pricings?.find((pricing) => pricing.validForAdultCount === trip.maxPax);

          accumulatedExperiences.push({
            ...optionData,
            price: pricing && formatPrice(pricing.price, 1),
            currency: experiencePrice?.currency
          });
        }
      }
      return accumulatedExperiences;
    }, Promise.resolve([]));

    const globalOptions = await Promise.all(
      trip.globalOptions.map(async (option) => {
        const optionData = (await getExperience({ experienceId: option.optionId }))?.data;
        const optionPriceExperience = newExperiences.find((experience) =>
          experience.extras?.find((extra) =>
            extra.id === option.optionId
            || extra.required?.find((required) => required.id === option.optionId)
            || extra.suggested?.find((suggested) => suggested.id === option.optionId)
          )
        );
        const optionPrice = optionPriceExperience?.priceObject?.extras?.find((extra) => extra.id === option.optionId);
        const pricing = optionPrice?.pricings?.find((pricing) => pricing.validForAdultCount === trip.maxPax);

        return {
          ...optionData,
          price: pricing && formatPrice(pricing.price, 1),
          currency: optionPriceExperience?.priceObject?.currency
        };
      })
    );

    experiences = [
      ...experiences,
      ...newExperiences,
      ...globalOptions,
    ];

    hotels = await Promise.all(
      trip.tripDays.map(async (day, index) => {
        if (!day.hotelId) {
          return null;
        }

        const hotelData = (await getHotel({ hotelId: day.hotelId }))?.data;
        const hotelPrice = await getHotelPrice({ hotelId: day.hotelId, trip, dayIndex: index });

        return {
          ...hotelData,
          id: day.hotelId,
          price: formatPrice(hotelPrice?.price),
          currency: hotelPrice?.currency
        };
      })
    );
  }

  return {
    bookings,
    experiences,
    hotels,
  }
};
