import { KoobAccordeonBloc } from "@koob/ui";
import { accordeonDetailsPolicies } from "../utils";

export default function HotelBookingPolicies({
  isLoading,
  translationData,
  policies,
}) {
  return (
    <div className="w-full">
      <KoobAccordeonBloc
        isLoading={isLoading}
        accordeonDetail={accordeonDetailsPolicies(translationData, policies)}
        variant={"booking"}
      />
    </div>
  );
}
