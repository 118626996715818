export const ProductTypeEnum = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
};

export const ProgramAccommodationTypeEnum = {
  NIGHT_TRANSFER: 'nightTransfer',
  NO_ACCOMMODATION: 'noAccomodation', // The typo is intentional to match the API
  FREE_ACCOMMODATION: 'freeAccomodation', // The typo is intentional to match the API
  HOTEL: 'hotel',
};

export const ExperienceTypeEnum = {
  PROGRAM: 'Program',
  TRANSFER: 'Transfer',
  ACTIVITY: 'Activity',
};

export const ExperiencePricingTypeEnum = {
  FIT: 'fit',
  SIC: 'sic',
  SERIES: 'series',
};

export const ExtraScopeEnum = {
  GLOBAL: 'global',
  DAY: 'day'
};

export const ExtraRequirementEnum = {
  REQUIRED: 'required',
  SUGGESTED: 'suggested',
};

export const OrderDirectionEnum = {
  DESC: 'desc',
  ASC: 'ASC',
};

export const OrderColumnEnum = {
  UPDATED_AT: 'updated_at',
};

export const ApiPollingStateEnum = {
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished'
};

export const RoleEnum = {
  KOOB_ADMIN: 'koob_admin',
  DMC_ADMIN: 'dmc_admin',
  DMC_USER: 'dmc_user',
  TO_ADMIN: 'to_admin',
  TO_USER: 'to_user',
};

export const AllotmentDayStateEnum = {
  ON_REQUEST: 'on_request',
  STOP_SALE: 'stop_sale',
  FREE_SALE: 'free_sale',
  ALLOTMENT: 'allotment',
};

export const ApiKeyRoles = {
  // Experience roles
  CREATE_EXPERIENCE: 'create_experience',
  UPDATE_EXPERIENCE: 'update_experience',
  READ_EXPERIENCE: 'read_experience',

  // Folder roles
  CREATE_FOLDER: 'create_folder',
  UPDATE_FOLDER: 'update_folder',
  READ_FOLDER: 'read_folder',

  // Booking roles
  READ_BOOKING: 'read_booking',
}

export const HotelStateEnum = {
  'AVAILABLE': 'available',
  'IN_PROGRESS': 'inProgress',
  'STAND_BY': 'standBy',
};

export const HotelContractStatusEnum = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
};
